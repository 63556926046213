import { Container } from "components/container";
import { Card } from "components/card";

import { getEdgeSettings } from "components/wrapper/components";
import { Col, Row } from "react-bootstrap";
import { AspectRatio, WrapperComponentProps } from "constants/types";
import { FC } from "react";
import { Im, Vi } from "types";
import { getSpeakingId } from "helpers/text-processing";

interface VideoWrapperProps extends WrapperComponentProps {
  ar?: AspectRatio;
  show_video_caption?: boolean;
  video?: Im[] | Vi[];
}

const VideoWrapper: FC<VideoWrapperProps> = (props) => {
  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={anchorNavId ? anchorNavId : `component__video-${props.index}`}
      className="section component__video"
    >
      {props.video?.length > 1 ? (
        <Row className={`entity-grid`}>
          {props.video.map((row) => {
            return row !== null ? (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                className={`mb-4`}
                key={row.id}
              >
                <Card
                  cardImg={{
                    media: row,
                    background: "grey-triangle",
                    ar: "ar169",
                  }}
                  title={row?.title || null}
                  variant={"vertical"}
                  lightbox={row.__typename === "Im" ? "image" : "video"}
                />
              </Col>
            ) : null;
          })}
        </Row>
      ) : (
        !!props.video?.length &&
        props.video.map((video, index) => (
          <Card
            key={index}
            cardImg={{
              media: video,
              background: "grey-triangle",
              ar: props.ar ? props.ar : "ar169",
            }}
            title={props.show_video_caption ? video?.title : null}
            variant={"vertical"}
            lightbox={"video"}
          />
        ))
      )}
    </Container>
  );
};

export default VideoWrapper;
