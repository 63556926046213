import { Container } from "components/container";
import { ProductDownloads } from "components/page/product-detail/product-overview";
import { Icon } from "components/shape";
import { RelatedProductsSlider } from "components/slider/related-product-slider";
import { Tabs } from "components/tabs";
import ComponentMapper from "components/wrapper";
import { getEdgeSettings } from "components/wrapper/components";
import { Tab, WrapperComponentProps } from "constants/types";
import { useLabels, useQueryState } from "helpers/hooks";
import { getSpeakingId } from "helpers/text-processing";
import { get } from "lodash-es";
import { FC } from "react";
import HeadingWrapper from "../heading";
import VideoWrapper from "../video";

interface TabsWrapperProps extends WrapperComponentProps {
  alignment?: "spread" | "left";
  icon?: Icon;
  sticky?: boolean;
  tabs?: Tab[];
}

const TabsWrapper: FC<TabsWrapperProps> = ({
  tabs,
  sticky,
  alignment = "spread",
  page,
  pc,
  downloads,
  ...props
}) => {
  const [activeTab, setActiveTab] = useQueryState<string | undefined>(
    [`tab.${props.index}`, `tab[${props.index}]`],
    undefined,
  );

  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  let hasVideo = false;

  tabs.forEach((currentElement) => {
    if (currentElement?.content[0]?.type === "video") {
      hasVideo = true;
    }
  });

  let downloadTab: Tab;
  const [downloadTitle, videoTitle, linkTitle] = useLabels(
    ["ui-66", "Downloads"],
    ["ui-240", "Video"],
    ["ui-28", "Links"],
  );

  if (downloads && Boolean(pc[0]?.product_tag?.length)) {
    downloadTab = {
      title: downloadTitle.title,
      component: <ProductDownloads {...downloads} />,
      disableHeader: true,
    };
  }

  let linkTab: Tab;
  if (pc?.[0]?.related_link && props.index === 0) {
    linkTab = {
      title: linkTitle.title,
      id: getSpeakingId({
        title: linkTitle.title,
        slug: linkTitle.title,
      }),
      component: (
        <RelatedProductsSlider
          title={linkTitle.title}
          related={pc?.[0]?.related_link}
        />
      ),
      disableHeader: true,
    };
  }

  let videoTab: Tab;
  if (pc?.[0]?.related_media && hasVideo) {
    videoTab = {
      title: videoTitle.title,
      id: getSpeakingId({
        title: videoTitle.title,
        slug: videoTitle.title,
      }),
      component: (
        <VideoWrapper
          {...props}
          video={pc?.[0]?.related_media}
          page={page}
          pc={pc}
        />
      ),
      disableHeader: true,
    };
  }

  return (
    <Container
      edge={getEdgeSettings(props)}
      background={props.background_color}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      layout={props.header_layout}
      id={anchorNavId ? anchorNavId : `component__tabs-${props.index}`}
      className="section component__tabs"
    >
      <HeadingWrapper {...props} />
      <Tabs
        key={`tab-element-${page.id}`}
        stickyNavigation={sticky}
        infoBox
        alignment={alignment}
        activeTabIndex={activeTab ? parseInt(activeTab) : undefined}
        onSelect={(newIndex) => setActiveTab(String(newIndex))}
        tabs={[
          ...tabs
            .filter(
              (tab) =>
                !pc?.[0]?.related_media ||
                get(tab, "navigation_text_entity.0.title", null) !==
                  videoTitle.title,
            )
            .map((tab) => ({
              title: get(
                tab,
                "navigation_text_entity.0.label",
                get(tab, "navigation_text"),
              ),
              id: getSpeakingId({
                title: get(
                  tab,
                  "navigation_text_entity.0.label",
                  get(tab, "navigation_text"),
                ),
                slug: get(tab, "navigation_text_entity.0.slug", null),
              }),
              icon: tab.icon,
              component: (
                <ComponentMapper
                  components={tab.content}
                  parent={tab}
                  page={page}
                  pc={pc}
                />
              ),
              disableHeader: true,
            })),
          videoTab,
          downloadTab,
          linkTab,
        ].filter(Boolean)}
      />
    </Container>
  );
};

export default TabsWrapper;
